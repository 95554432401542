import React, { memo } from 'react';
import { Accordion, Anchor, Card, Group, SimpleGrid, Stack, Text } from '@mantine/core';
import { navigate } from 'gatsby';
import {
    IconUserSearch,
    IconMap,
    IconRobot,
    IconShoppingBag,
    IconTimeline,
    IconPointerDollar,
    IconVocabulary,
    IconLanguage,
    IconActivity,
    IconActivityHeartbeat,
    IconClockPlay,
    IconChartHistogram,
    IconChartDots,
    IconClockShare,
    IconPlayerPlay,
    IconDoorExit,
    IconRobotFace,
    IconTableOptions,
    IconAbc,
    IconMessage2Question,
    IconSettings,
    IconMailbox,
    IconBrain,
    IconMessageLanguage,
    IconClipboard,
    IconFolder,
    IconCalendarEvent,
    IconMeteor,
    IconNumber,
    IconUsersGroup,
    IconBrandTeams,
    IconPackage,
    IconTicket,
    IconUserDollar,
    IconShoppingCart,
    IconHistory,
    IconFileExcel,
    IconAdjustments,
    IconBooks,
    IconOlympics,
    IconTournament,
    IconList,
    IconTable
} from '@tabler/icons-react';
import { RealmRole, Role } from './users/constants';
import { useCheckRoles } from '../context/AuthProvider';
import { useTranslation } from '../components/localization';

const menu = [
    { title: 'Maps', to: '/manage/maps', icon: IconMap, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'NPC', to: '/manage/npc', icon: IconRobot, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'Subcriptions', to: '/manage/subcriptions', icon: IconPointerDollar, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'Items', to: '/manage/items', icon: IconShoppingBag, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Avatar Frame', to: '/manage/avatar-frame', icon: IconShoppingBag, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Bots', to: '/manage/bots', icon: IconRobotFace, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Users', to: '/manage/users', icon: IconUserSearch, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Roles', to: '/manage/roles', icon: IconUsersGroup, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Locaiization', to: '/manage/locales', icon: IconLanguage, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Locale Props', to: '/manage/locales-props', icon: IconMessageLanguage, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Achievement', to: '/manage/achievement', icon: IconLanguage, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Level Mapping', to: '/manage/level-mapping', icon: IconLanguage, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Events', to: '/manage/events', icon: IconCalendarEvent, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Maintenance', to: '/manage/maintenance', icon: IconCalendarEvent, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Title', to: '/manage/title', icon: IconCalendarEvent, roles: [Role.Admin, RealmRole.Admin] },
]

const contentMenu = [
    { title: 'Banks', to: '/manage/banks', icon: IconFolder, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'Dictionary', to: '/manage/dictionary', icon: IconVocabulary, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'Classes', to: '/manage/classes', icon: IconClipboard, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Learns', to: '/manage/learns', icon: IconAbc, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Reports', to: '/report/report-learning', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Reports classes', to: '/report/report-classes', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
]

const reportMenu = [
    { title: 'Total Users', to: '/report/total', icon: IconNumber, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Total Users*', to: '/report/total-user-with-blocks', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Active Users', to: '/report/active', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Active Users*', to: '/report/active-with-blocks', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Sessions', to: '/report/sessions', icon: IconClockShare, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Sessions*', to: '/report/sessions-with-blocks', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Churn Rate', to: '/report/churnrate', icon: IconDoorExit, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Activities', to: '/manage/activities', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Playing Time', to: '/report/active/?tab=playing', icon: IconPlayerPlay, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Retention', to: '/report/retention', icon: IconClockPlay, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Stickiness', to: '/report/stickiness', icon: IconChartDots, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Realtime', to: '/report/realtime', icon: IconTimeline, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'RealtimeTable', to: '/report/realtime-table', icon: IconTimeline, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Max CCU', to: '/report/ccu', icon: IconMeteor, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Rooms', to: '/report/brainwar', icon: IconBrain, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Load Time', to: '/report/load-time', icon: IconActivityHeartbeat, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Player', to: '/report/player', icon: IconActivityHeartbeat, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Monetization', to: '/report/monetization', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Arena', to: '/report/arena', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'First Session', to: '/report/first-session', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'New Users*', to: '/report/new-user', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Summary', to: '/report/summary', icon: IconTimeline, roles: [Role.Admin, RealmRole.Admin], color: 'red' },
    { title: 'Weekly Sessions', to: '/report/sessions-weekly', icon: IconClockShare, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Weekly New Users', to: '/report/new-users-weekly', icon: IconClockShare, roles: [Role.Admin, RealmRole.Admin] },
]

const userActivitieMenu = [
    { title: 'IAP Report', to: '/report/subcription-sale-group-source', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'IAP Table', to: '/manage/user-subcription-source', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'User Activities', to: '/manage/user-activities', icon: IconActivityHeartbeat, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Count', to: '/report/user-count', icon: IconActivityHeartbeat, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Delete', to: '/report/user-delete', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Checkin', to: '/report/user-checkin-daily', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Gold', to: '/report/user-gold', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Exp', to: '/report/user-exp', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Play Game', to: '/report/user-play-game-daily', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    // { title: 'User Play', to: '/report/user-play-game', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Subcription', to: '/report/subcription-sale', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Bought Items', to: '/report/user-bought-items', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Use Items', to: '/report/user-use-items', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin] },
]

const gameMenu = [
    { title: 'Games', to: '/manage/games', icon: IconTableOptions, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Quests', to: '/manage/quests', icon: IconMessage2Question, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Mailbox', to: '/manage/mailbox', icon: IconMailbox, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Popups', to: '/manage/popup-notification', icon: IconMailbox, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Lands', to: '/manage/config-land-item', icon: IconSettings, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'System', to: '/manage/configs', icon: IconSettings, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Voucher', to: '/manage/vouchers', icon: IconShoppingBag, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Pet Config', to: '/manage/pet-config', icon: IconTableOptions, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Reward Online', to: '/manage/reward-online', icon: IconTableOptions, roles: [Role.Admin, RealmRole.Admin] },
]

const salesMenu = [
    { title: 'Teams', to: '/sales/teams', icon: IconBrandTeams, roles: [RealmRole.Admin, RealmRole.SalesManager] },
    { title: 'Packages', to: '/sales/packages', icon: IconPackage, roles: [Role.SalesMember, RealmRole.SalesMember] },
    { title: 'Orders', to: '/sales/orders', icon: IconPackage, roles: [RealmRole.Admin, RealmRole.SalesManager, Role.SalesMember, RealmRole.SalesMember] },
    { title: 'Codes', to: '/sales/codes', icon: IconTicket, roles: [Role.SalesMember] },
    { title: 'Sellers', to: '/sales/sellers', icon: IconUserDollar, roles: [RealmRole.Admin] },
]

const tournamentMenu = [
    { title: 'Events', to: '/tournaments/events', icon: IconCalendarEvent, roles: [RealmRole.Admin, Role.Admin] },
    { title: 'Matches', to: '/tournaments/matches', icon: IconTable, roles: [RealmRole.Admin, Role.Admin] },
]

function Shortcut({ data, toggle }: any) {
    const { title, to, roles, icon, disabled, color } = data
    const right = useCheckRoles(roles)
    if (!right) return <></>

    const Icon = icon;

    return <Anchor key={title} c={color ?? 'blue'} onClick={_ => {
        navigate(to)
        toggle && toggle()
        console.log(122121212)
    }} pl={'xs'}>
        <Group align='center' gap={'xs'}><Icon size={18} /><Text>{title}</Text></Group>
    </Anchor>
}

let openedMenu: string | null = ''
export const Shortcuts = memo(function ({ toggle }: any) {
    const { t } = useTranslation()
    const su = useCheckRoles([RealmRole.Admin, Role.Editor])
    const sm = useCheckRoles([RealmRole.Admin, RealmRole.SalesManager, RealmRole.SalesMember, Role.SalesMember])

    const manage = menu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)
    const content = contentMenu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)
    const games = gameMenu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)
    const reports = reportMenu.map((x, idx) => <Shortcut data={x} key={idx} toggle={toggle} />)
    const userActivities = userActivitieMenu.map((x, idx) => <Shortcut data={x} key={idx} toggle={toggle} />)
    const tournaments = tournamentMenu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)
    const sales = salesMenu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)

    return <Card withBorder radius={'lg'} p={0}>
        <Accordion defaultValue={openedMenu} onChange={x => { openedMenu = x }}>
            {su && <Accordion.Item key={'content'} value={'content'}>
                <Accordion.Control icon={<IconBooks />}>{t('Content')}</Accordion.Control>
                <Accordion.Panel>
                    <Stack>
                        {content}
                    </Stack>
                </Accordion.Panel>
            </Accordion.Item>}
            {su && <Accordion.Item key={'manage'} value={'manage'}>
                <Accordion.Control icon={<IconSettings />}>{t('Manage')}</Accordion.Control>
                <Accordion.Panel>
                    <Stack>
                        {manage}
                    </Stack>
                </Accordion.Panel>
            </Accordion.Item>}
            {su && <Accordion.Item key={'configs'} value={'configs'}>
                <Accordion.Control icon={<IconAdjustments />}>{t('Configs')}</Accordion.Control>
                <Accordion.Panel>
                    <SimpleGrid>
                        {games}
                    </SimpleGrid>
                </Accordion.Panel>
            </Accordion.Item>}

            {su && <Accordion.Item key={'activities'} value={'activities'}>
                <Accordion.Control icon={<IconHistory />}>{t('Activities')}</Accordion.Control>
                <Accordion.Panel>
                    <SimpleGrid>
                        {userActivities}
                    </SimpleGrid>
                </Accordion.Panel>
            </Accordion.Item>}

            {(su) && <Accordion.Item key={'tournaments'} value={'tournaments'}>
                <Accordion.Control icon={<IconTournament />}>{t('Tournaments')}</Accordion.Control>
                <Accordion.Panel>
                    <SimpleGrid>
                        {tournaments}
                    </SimpleGrid>
                </Accordion.Panel>
            </Accordion.Item>}

            {(sm) && <Accordion.Item key={'sales'} value={'sales'}>
                <Accordion.Control icon={<IconShoppingCart />}>{t('Sales')}</Accordion.Control>
                <Accordion.Panel>
                    <SimpleGrid>
                        {sales}
                    </SimpleGrid>
                </Accordion.Panel>
            </Accordion.Item>}

            {su && <Accordion.Item key={'reports'} value={'reports'}>
                <Accordion.Control icon={<IconFileExcel />}>{t('Reports')}</Accordion.Control>
                <Accordion.Panel>
                    <SimpleGrid>
                        {reports}
                    </SimpleGrid>
                </Accordion.Panel>
            </Accordion.Item>}
        </Accordion>
    </Card>
})

