import { ActionIcon, useMantineColorScheme, useComputedColorScheme, Group } from '@mantine/core';
import { IconSun, IconMoon } from '@tabler/icons-react';
import React from 'react';

export function ThemeSwitcher() {
    const { colorScheme, setColorScheme } = useMantineColorScheme();
    const computedColorScheme = useComputedColorScheme('dark', { getInitialValueInEffect: true });

    return (
        <Group justify="center">
            <ActionIcon
                onClick={() => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')}
                variant="light"
                aria-label="Toggle color scheme"
                p={5}
            >
                {colorScheme === 'dark' && <IconSun />}
                {colorScheme === 'light' && <IconMoon />}
            </ActionIcon>
        </Group>
    );
}