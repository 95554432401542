import React from 'react';
import { Box, Button, Card, Flex, Group, Image, Stack, Text, Title } from '@mantine/core';
import { useAuthContext } from '../context/AuthProvider';
import { RealmRole, Role } from './users/constants';
import { useTranslation } from '../components/localization';
import { jwtDecode } from 'jwt-decode';

export function Welcome() {
    const { userInfo, signout } = useAuthContext()
    const { t } = useTranslation()
    // console.log(userInfo)
    const { accessToken } = userInfo
    const decoded = jwtDecode(accessToken);
    // @ts-ignore
    const roles = [...decoded?.roles, ...decoded?.realm_access?.roles].join(',')
    if (!userInfo) return <></>

    return <Card withBorder radius={'lg'} w={'100%'}>
        <Stack gap={'xs'}>
            <Title order={3}>{t('Welcome back')}</Title>
            <Text c={'blue'} fz={'h4'} fw={600}>{`${userInfo?.username}`}</Text>
            {/* <Text>Your role is {roles}</Text> */}
            {/* <Button variant='light' color='gray' onClick={signout}>{t('Sign out')}</Button> */}
            {/* <Text fw={300} size='sm'>You will never miss important product updates, latest news and community QA sessions. Our newsletter is once a week, every Sunday.</Text>
                <Group mt={10}>
                    <Button variant='light'>{t('Subcribe')}</Button>
                    <Button variant='light' color='gray' onClick={signout}>{t('Sign out')}</Button>
                </Group> */}
        </Stack>
    </Card>
}